import merge from '~lodash.mergewith'
import * as CoreSdk from '~@sentry/core'
import { captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } from '~@sentry/browser'
import { extraErrorDataIntegration, reportingObserverIntegration } from '~@sentry/integrations'
import { init, browserTracingIntegration } from '~@sentry/vue'

export { init }
export const SentrySdk = { ...CoreSdk, ...{ captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } }

/** @type {string[]} */
const DISABLED_INTEGRATION_KEYS = []

/**
 * @typedef {Parameters<typeof init>[0]} InitConfig
 * @param {import('@nuxt/types').Context} ctx
 * @return {Promise<InitConfig>}
 */
export function getConfig (ctx) {
  /** @type {InitConfig} */
  const config = {
    dsn:"https:\u002F\u002F3d83e9805b27894f7e819c9b4138c3b5@o4504643716317184.ingest.us.sentry.io\u002F4507457729200128",
    environment:"production",
    beforeSend:(event) => {
        const excludedFilenames = ['gtm.js', 'sst.js'];

        // filter out errors brought by stuff loaded from GTM
        if (
          event.exception?.values?.[0]?.stacktrace?.frames?.some((f) =>
            excludedFilenames.some((filename) => f.filename?.includes(filename))
          )
        ) {
          return null;
        }

        return event;
      },
    release:"39de5e2ea3f2eacb197313deae17f0c266773d4f",
    sampleRate:0.05,
    tracesSampleRate:0.5,
  }

  /** @type {NonNullable<InitConfig>['integrations']} */
  const resolvedIntegrations = [
    extraErrorDataIntegration(),
    reportingObserverIntegration({ types:["crash"] }),
  ]

  resolvedIntegrations.push(browserTracingIntegration({
    router: ctx.app.router,
    ...{"routeLabel":"name"},
    ...{},
  }))
  merge(config, {"trackComponents":true}, {"tracesSampleRate":0.5})

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
